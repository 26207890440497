import React from 'react'
import View from '../View'
import Moment from 'moment'
import {
  getArticles,
  testEmail,
  createEmailList,
  getNextMonday,
  getScheduledCampaigns,
  postNewCampaign,
  deleteCampaign,
} from '../../utils/pulse'
import { getCurrentUser } from '../../utils/auth'
import EmailPreview from '../EmailPreview'
import ScheduleForm from '../Form/pulse-weekly-summary-schedule'
import AvailableArticles from '../Pulse/AvailableArticles'
import CampaignsList from '../Pulse/CampaignsList'
import Tabs from '../Tabs'
import WaitLoader from '../WaitLoader'
import Layout from '../Layout'
import * as styles from '../Form/form.module.css'

class PulseEmail extends React.Component {
  constructor(props) {
    super(props)
    let user = getCurrentUser()
    let monday = Moment().subtract(1, 'd').startOf('isoWeek')
    let articleDays = Moment().diff(monday, 'days')
    this.state = {
      articles: [],
      selectedArticles: [],
      emailListName: '',
      scheduleDate: getNextMonday(),
      emailTo: user.email,
      articleDays: articleDays,
      scheduledCampaigns: [],
      scheduleCampaignResponse: '',
      testEmailResponse: '',
      lastMonday: monday.format('dddd, MM/DD/YYYY'),
      errorMessage: '',
      loading: true,
    }
  }

  componentDidMount() {
    this.updateScheduledCampaigns()
    getArticles(this.state.articleDays)
      .then((articles) => {
        if (!articles) {
          articles = []
        }
        this.setState({
          articles: articles,
          loading: false,
        })
      })
      .catch((err) => {
        this.setErrorMessage(err)
      })
  }

  setSelectedArticles = (articles) => {
    this.setState({
      selectedArticles: articles,
    })
  }

  updateScheduledCampaigns = () => {
    getScheduledCampaigns()
      .then(
        function (data) {
          this.setState({
            scheduledCampaigns: data.campaigns,
            loading: false
          })
        }.bind(this)
      )
      .catch((err) => {
        this.setErrorMessage(err)
        console.log(err)
      })
  }

  sendTestEmail = () => {
    let emailPost = {
      subject:
        'Pulse Update | ' +
        Moment(this.state.scheduleDate).format('MM/DD/YYYY'),
      toAddress: this.state.emailTo,
      sendDate: Date.parse(
        this.state.scheduleDate.format('MM/DD/YYYY h:mm:ss a')
      ),
      pages: this.state.articles,
    }

    testEmail(emailPost)
      .then((data) => {
        console.log(data)
        this.setState({
          testEmailResponse: data.message,
        })
      })
      .catch((err) => {
        this.setErrorMessage(err)
        console.log(err)
      })
  }

  submitNewEmailList = (event) => {
    event.preventDefault()
    createEmailList({ name: this.state.emailListName })
      .then(function (data) {
        console.log(data)
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  updateEmailList = (event) => {
    this.setState({
      emailListName: event.currentTarget.value,
    })
  }

  setErrorMessage = (error) => {
    this.setState({
      errorMessage: error,
      loading: false,
    })
  }

  submitScheduleDate = (event) => {
    event.preventDefault()
    this.setState({
      loading: true,
    })
    let campaignData = {
      subject:
        'Pulse Update | ' +
        Moment(this.state.scheduleDate).format('MM/DD/YYYY'),
      scheduleDate: Date.parse(
        Moment(this.state.scheduleDate).format('MM/DD/YYYY h:mm:ss a')
      ),
      isTestCampaign: false,
      pulseEmailList: '5cd090accbb1557d25505a14',
      template: 'pulse-summary',
    }
    postNewCampaign(campaignData)
      .then((response) => {
        this.setState(
          {
            scheduleCampaignResponse:
              'New campaign scheduled for ' +
              Moment(this.state.scheduleDate).format('MM/DD/YYYY h:mm:ss a'),
            loading: false,
          },
          () => {
            this.updateScheduledCampaigns()
          }
        )
        console.log(response)
      })
      .catch((err) => {
        console.log(err)
        this.setErrorMessage(err)
        this.setState(
          {
            scheduleCampaignResponse: err,
            disableSubmit: false,
          },
          () => {
            this.updateScheduledCampaigns()
          }
        )
      })
  }

  updateScheduleDate = (scheduleDate) => {
    if (Date.parse(scheduleDate)) {
      console.log(scheduleDate)
      this.setState({
        scheduleDate: Date.parse(scheduleDate),
      })
    }
  }

  deleteCampaign = (id) => {
    this.setState({
      loading: true,
    })
    deleteCampaign(id)
      .then((response) => {
        console.log(response)
        this.updateScheduledCampaigns()
      })
      .catch((err) => {
        this.setErrorMessage(err)
        console.log(err)
      })
  }

  render() {
    let emailPost = {
      subject:
        'Pulse Update | ' +
        Moment(this.state.scheduleDate).format('MM/DD/YYYY'),
      toAddress: this.state.emailTo,
      sendDate: Date.parse(
        Moment(this.state.scheduleDate).format('MM/DD/YYYY h:mm:ss a')
      ),
    }
    let campaignSubject =
      'Pulse Update | ' + Moment(this.state.scheduleDate).format('MM/DD/YYYY')
    let errorMessage = null
    if (this.state.errorMessage) {
      errorMessage = (
        <div className={styles['form__error']}>{this.state.errorMessage}</div>
      )
    }
    return (
      <Layout>
        <View title="Pulse Email Summary Campaign">
          {errorMessage}
          <Tabs>
            <div label="Articles to Send">
              <AvailableArticles
                articles={this.state.articles}
                fromDate={this.state.lastMonday}
                setSelected={this.setSelectedArticles}
              />
            </div>
            <div label="Email Preview">
              <EmailPreview
                pulse={true}
                email={emailPost}
                pages={this.state.articles}
                setErrorMessage={this.setErrorMessage}
              />
              <button
                onClick={this.sendTestEmail}
                className={styles['form__button']}
              >
                Send Test Email to {this.state.emailTo}
              </button>
              <div>{this.state.testEmailResponse}</div>
            </div>
            <div label="Schedule Email">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <h2>Schedule a New Campaign</h2>
                  <ScheduleForm
                    scheduleDate={this.state.scheduleDate}
                    handleSubmit={this.submitScheduleDate}
                    handleUpdate={this.updateScheduleDate}
                    disableSubmit={this.loading}
                    subject={campaignSubject}
                  />
                  <div>{this.state.scheduleCampaignResponse}</div>
                </div>
                <div className="col-md-1 col-xs-12"></div>
                <div className="col-md-7 col-xs-12">
                  <CampaignsList
                    scheduledCampaigns={this.state.scheduledCampaigns}
                    deleteCampaign={this.deleteCampaign}
                  />
                </div>
              </div>
            </div>
          </Tabs>
        </View>
        <WaitLoader loading={this.state.loading} />
      </Layout>
    )
  }
}

export default PulseEmail
